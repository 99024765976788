import '@angular/localize/init';
import 'zone.js';

(window as any).global = window;
(window as any).Buffer = (window as any).Buffer || [];

import 'core-js/proposals/reflect-metadata';
import 'core-js/es/reflect';

// Typescript helpers
import 'tslib';

// IE11 needs polyfills
import 'core-js/es/array';
import 'core-js/es/object';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';

// Necessary for Web Animations API
import 'web-animations-js';

// Evergreen browsers require these.
// import 'core-js/es6/reflect';
// import 'core-js/es7/reflect';

// ALL Firefox browsers require the following to support `@angular/animation`.
import 'web-animations-js/web-animations.min';

// include for differential loading
import 'core-js';
